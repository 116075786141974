import React, { useContext, useMemo, useEffect } from 'react';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';

import auth from '../../../services/Auth/Firebase';
import { AuthContext } from 'contexts/AuthContext';

import '../Auth.sass';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';

const appleSignInProvider = new firebase.auth.OAuthProvider('apple.com');
appleSignInProvider.addScope('name');
appleSignInProvider.addScope('email');

const Login = () => {
  const navigate = useNavigate();

  const { isSignedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isSignedIn) {
      navigate(ROUTES.HOME);
    }
  }, [navigate, isSignedIn]);

  const uiConfig = useMemo(
    () => ({
      signInFlow: 'popup',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        appleSignInProvider.providerId,
      ],
      callbacks: {},
    }),
    [],
  );

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui_container', uiConfig);
  }, [uiConfig]);

  return (
    <div className="auth-wrapper">
      <img
        className="auth-wrapper__logo"
        src="./images/Usefull_logo.png"
        alt="Usefull logo"
      />
      <div className="auth-wrapper__title">Sign in:</div>
      <div id="firebaseui_container"></div>
    </div>
  );
};

export default Login;
